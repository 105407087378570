import { fetchEventSource } from "@microsoft/fetch-event-source";
import axios from "axios";
import { Mention } from "primereact/mention";
import { Sidebar } from "primereact/sidebar";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import Plot from "react-plotly.js";
import { useLocation, useNavigate } from "react-router-dom";
import remarkGfm from "remark-gfm";
import CommunicationIcon from "../Assets/Images/communication-icon.svg";
import AnswerIcon from "../Assets/Images/nc.png";
import Header from "../Common/Header";
import HomeSidebar from "../Common/HomeSidebar";
import CommonValues from "../Common/Utils";
import SupportLoader from "../CommonControls/SupportLoader";
import NCChatBotConversationHistory from "./NCChatBotConversationHistory";

interface ChatResponse {
  conversationId: string;
  answer: string;
  thoughts: string;
  sources: string[];
  imageURLs: string[];
  videoURLs: string[];
  imageSidebar: boolean;
  citationBaseUrl: string;
  error: string;
  question: string;
  chatId: string;
  like: boolean;
  disLike: boolean;
  feedback: string;
  showFeedback: boolean;
  feedbackLoading: boolean;
  plotlyData: { data: string; layout: string };
  transactionId: string;
  responseStreaming: boolean;
}

class ChatRequest {
  ConversationId: string = "";
  ChatHistory: { Question: string; Answer: string }[] = [];
  Question: string = "";
}

export default function NCChatBot() {
  const [question, setQuestion] = useState("");
  const [questionLoading, setQuestionLoading] = useState(false);
  const [conversationLoading, setConversationLoading] = useState(false);
  const [showConversationHistory, setShowConversationHistory] = useState(false);
  const [chatResponses, setChatResponses] = useState<ChatResponse[]>([]);
  const [avatarName, setAvatarName] = useState<string | null>("");
  const toast: any = useRef("");
  const navigate = useNavigate();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const [chatBotEmbeded, setChatBotEmbeded] = useState(
    location.state?.embeded || window.location.pathname.endsWith("embeded")
  );
  const [suggestions, setSuggestions] = useState([
    { name: "Rater Agent", nickname: "RaterAgent" },
    { name: "Talk To Ams Agent", nickname: "TalkToAmsAgent" },
  ]);

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    localStorage.setItem("Embeded", String(chatBotEmbeded));

    setChatBotEmbeded(localStorage.getItem("Embeded") === "true");

    getConversationHistory(window.location.href.split("/")[4].substring(0, 36));

    adjustTextareaHeight();
    setAvatarName(localStorage.getItem("AvatarName"));
  }, [window.location.href.split("/")[4]]);

  useEffect(() => {
    if (chatResponses.length > 1) {
      scrollToBottomForQuestion(true);
      scrollToBottomForAnswer(true);
    }
  }, [chatResponses.length]);

  const onSearch = (event: { query: string }) => {
    const query = event.query.toLowerCase();
    const filteredSuggestions = suggestions.filter((suggestion) =>
      suggestion.nickname.toLowerCase().startsWith(query)
    );
    setSuggestions(filteredSuggestions);
  };

  const itemTemplate = (suggestion: { name: string; nickname: string }) => {
    return (
      <div className="flex align-items-center py-0">
        <span className="flex flex-column ml-2 text-10">
          {suggestion.name}
          <small
            style={{ fontSize: ".75rem", color: "var(--text-color-secondary)" }}
          >
            @{suggestion.nickname}
          </small>
        </span>
      </div>
    );
  };

  const getConversationHistory = (conversationId: string | null) => {
    if (
      conversationId === undefined ||
      conversationId === null ||
      conversationId === ""
    ) {
      return;
    }
    setConversationLoading(true);
    const token = CommonValues.GetToken();

    const config = {
      method: "get",
      url: `${process.env.REACT_APP_NcSupport_Url}/api/ncsupportbot/nc-support-bot-conversation-history/${conversationId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        const data = response.data;
        let conversationHistory: ChatResponse[] = [];
        data.forEach((conversation: any) => {
          conversationHistory.push({
            conversationId: conversation.conversationId,
            answer: conversation.response,
            thoughts: "",
            sources: conversation.pageURL,
            imageURLs: conversation.imageURL,
            videoURLs: conversation.videoURL,
            imageSidebar: false,
            citationBaseUrl: "",
            error: "",
            question: conversation.question,
            chatId: conversation.chatId,
            like: conversation.like === null ? false : conversation.like,
            disLike:
              conversation.disLike === null ? false : conversation.disLike,
            feedback: "",
            showFeedback: false,
            feedbackLoading: false,
            plotlyData: { data: "", layout: "" },
            transactionId: "",
            responseStreaming: false,
          });
        });
        setChatResponses(conversationHistory);
        setTimeout(() => {
          scrollToBottomForQuestion(false);
          scrollToBottomForAnswer(false);
        }, 100);

        setConversationLoading(false);
      })
      .catch((error: any) => {
        setConversationLoading(false);
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting conversation history",
            life: 3000,
          });
        }
      });
  };

  const onQuestionChange = (e: any) => {
    setQuestion(e.target.value);
    adjustTextareaHeight();
  };
  const onFeedbackChange = (chatId: string, newFeedback: string) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? { ...response, feedback: newFeedback }
          : response
      )
    );
  };
  const scrollToBottomForQuestion = (scrollBottom: boolean) => {
    if (chatContainerRef.current) {
      let anchorTags = document.querySelectorAll("a");
      anchorTags.forEach((anchorTag: any) => {
        anchorTag.setAttribute("target", "_blank");
      });
      const lastAnswerElement = document.querySelector(
        ".last-question-block-nc-chat-bot"
      );
      if (lastAnswerElement) {
        const { offsetTop } = lastAnswerElement as HTMLElement;
        chatContainerRef.current.scrollTop = scrollBottom ? offsetTop : 0;
      }
    }
  };
  const scrollToBottomForAnswer = (scrollBottom: boolean) => {
    if (chatContainerRef.current) {
      let anchorTags = document.querySelectorAll("a");
      anchorTags.forEach((anchorTag: any) => {
        anchorTag.setAttribute("target", "_blank");
      });
      const lastAnswerElement = document.querySelector(
        ".last-answer-block-nc-chat-bot"
      );
      if (lastAnswerElement) {
        const { offsetTop } = lastAnswerElement as HTMLElement;
        chatContainerRef.current.scrollTop = scrollBottom ? offsetTop - 70 : 0;
      }
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };
  const onThumbsUpClick = (chatId: string, like: boolean, disLike: boolean) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? {
              ...response,
              like: like,
              disLike: disLike,
            }
          : response
      )
    );
    updateReaction(chatId, like, disLike);
  };
  const onThumbsDownClick = (
    chatId: string,
    like: boolean,
    disLike: boolean
  ) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? {
              ...response,
              like: like,
              disLike: disLike,
            }
          : response
      )
    );
    updateReaction(chatId, like, disLike);
  };
  const onFeedbackSubmit = (chatId: string, feedBack: string) => {
    if (feedBack === "" || feedBack === null || feedBack === undefined) {
      return;
    }
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? { ...response, feedbackLoading: true }
          : response
      )
    );
    updateFeedback(chatId, feedBack);
  };
  const onEnterClick = (e: any) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      sendQuestion(question);
    }
  };
  const toggleFeedbackVisibility = (chatId: string) => {
    setChatResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.chatId === chatId
          ? { ...response, showFeedback: !response.showFeedback }
          : response
      )
    );
  };
  const sendQuestion = async (que: any) => {
    if (que === "") {
      return;
    }
    let body: ChatRequest = {
      ConversationId: window.location.href.split("/")[4].substring(0, 36),
      ChatHistory: [],
      Question: que,
    };
    if (que.startsWith("@RaterAgent")) {
      askRaterQuestion(que.substring(12));
      return;
    } else if (que.startsWith("@TalkToAmsAgent")) {
      askSQLQuestion(que.substring(16));
      return;
    } else {
      chatResponses.forEach((response: ChatResponse) => {
        body.ChatHistory.push({
          Question: response.question,
          Answer: response.answer,
        });
      });
      body.ChatHistory.push({ Question: que, Answer: "" });
    }
    const newQuestion: ChatResponse = {
      conversationId: "",
      question: que,
      answer: "",
      thoughts: "",
      sources: [],
      imageURLs: [],
      videoURLs: [],
      imageSidebar: false,
      citationBaseUrl: "",
      error: "",
      chatId: "",
      like: false,
      disLike: false,
      feedback: "",
      showFeedback: false,
      feedbackLoading: false,
      plotlyData: { data: "", layout: "" },
      transactionId: "",
      responseStreaming: false,
    };
    setChatResponses((prevResponses: any) => [...prevResponses, newQuestion]);
    setQuestion("");
    scrollToBottomForQuestion(true);
    setQuestionLoading(true);

    const index = chatResponses.length;
    const token = CommonValues.GetToken();
    try {
      const ctrl = new AbortController();
      await fetchEventSource(
        `${process.env.REACT_APP_NcSupport_Url}/api/ncsupportbot/chat`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
          signal: ctrl.signal,
          onopen(response: Response): Promise<void> {
            if (response.status === 401) {
              CommonValues.Logout(navigate);
            } else if (response.status !== 200) {
              setQuestionLoading(false);
              toast.current.show({
                severity: "error",
                detail: "Unknown error while sending question",
                life: 3000,
              });
            }
            return Promise.resolve();
          },
          onmessage(event: any) {
            let responseObject = JSON.parse(event.data);
            if (responseObject.Answer !== "") {
              setQuestionLoading(false);
            }
            setChatResponses((prevResponses: ChatResponse[]) => {
              const updatedResponses = [...prevResponses];

              const newAnswer =
                (updatedResponses[index]?.answer || "") + responseObject.Answer;

              updatedResponses[index] = {
                ...newQuestion,
                answer: newAnswer,
                chatId: responseObject.ChatId,
                conversationId: responseObject.ConversationId,
                sources: responseObject.Sources,
                imageURLs: responseObject.Images,
                videoURLs: responseObject.Videos,
                responseStreaming: responseObject.ResponseLoading,
              };
              return updatedResponses;
            });

            scrollToBottomForAnswer(true);
          },
          onclose() {
            console.log("Connection closed by the server");
          },
          onerror(error: any) {
            setQuestionLoading(false);
          },
        }
      );
    } catch (error: any) {}
  };
  const askSQLQuestion = async (question: any) => {
    if (question === "") {
      return;
    }
    setQuestionLoading(true);

    const newQuestion: ChatResponse = {
      conversationId: "",
      question: "@TalkToAmsAgent " + question,
      answer: "",
      thoughts: "",
      sources: [],
      imageURLs: [],
      videoURLs: [],
      imageSidebar: false,
      citationBaseUrl: "",
      error: "",
      chatId: "",
      like: false,
      disLike: false,
      feedback: "",
      showFeedback: false,
      feedbackLoading: false,
      plotlyData: { data: "", layout: "" },
      transactionId: "",
      responseStreaming: false,
    };
    setChatResponses((prevResponses: any) => [...prevResponses, newQuestion]);
    setQuestion("");
    scrollToBottomForQuestion(true);

    const token = CommonValues.GetToken();
    const index = chatResponses.length;

    try {
      const ctrl = new AbortController();
      await fetchEventSource(
        `${process.env.REACT_APP_SqlAgent_Url}/sql-agent`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ Question: question }),
          signal: ctrl.signal,
          onopen(response: any): Promise<void> {
            if (response) {
              if (response.status === 401) {
                CommonValues.Logout(navigate);
              }
            }
            return Promise.resolve();
          },
          onmessage(event: any) {
            let data = JSON.parse(event.data);

            setQuestionLoading(false);
            setQuestion("");

            let formattedAnswer = data.answer;

            if (data.error_message === "context_length_exceeded") {
              formattedAnswer =
                "Talk To AMS response terminated due to large data size. Please try again.";
            }
            if (data.error_message === "429") {
              formattedAnswer =
                "Talk To AMS response ended due to an excessive number of questions. Please try again.";
            }

            setChatResponses((prevResponses: any) => {
              const updatedResponses = [...prevResponses];
              updatedResponses[index] = {
                ...newQuestion,
                transactionId: data.transactionId,
                question: "@TalkToAmsAgent " + data.question,
                answer:
                  formattedAnswer !== ""
                    ? updatedResponses[index].answer + formattedAnswer
                    : updatedResponses[index].answer + "\n",
                plotlyData: {
                  data: data.plotly_data,
                  layout: data.plotly_layout,
                },
                responseStreaming: data.response_streaming,
              };
              return updatedResponses;
            });
            scrollToBottomForAnswer(true);
          },
          onclose() {
            console.log("Connection closed by the server");
          },
          onerror(error: any) {
            setQuestionLoading(false);
          },
        }
      );
    } catch (error: any) {}
  };
  const askRaterQuestion = async (question: any) => {
    if (question === "") {
      return;
    }
    setQuestionLoading(true);

    const newQuestion: ChatResponse = {
      conversationId: "",
      question: "@RaterAgent " + question,
      answer: "",
      thoughts: "",
      sources: [],
      imageURLs: [],
      videoURLs: [],
      imageSidebar: false,
      citationBaseUrl: "",
      error: "",
      chatId: "",
      like: false,
      disLike: false,
      feedback: "",
      showFeedback: false,
      feedbackLoading: false,
      plotlyData: { data: "", layout: "" },
      transactionId: "",
      responseStreaming: false,
    };
    setChatResponses((prevResponses: any) => [...prevResponses, newQuestion]);
    setQuestion("");
    scrollToBottomForQuestion(true);

    const token = CommonValues.GetToken();
    const index = chatResponses.length;
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_RateAgent_Url}/query`,
      // url: `http://127.0.0.1:8000/query`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify({ Question: question }),
    };

    axios(config)
      .then((response: any) => {
        console.log(response);
        setQuestionLoading(false);
        setQuestion("");
        const data = response.data;

        const index = chatResponses.length;
        const responseData: ChatResponse = {
          ...data,
          transactionId: data?.transactionId,
          question: data?.question,
          answer: data?.response,
        };

        setChatResponses((prevResponses: any) => {
          const updatedResponses = [...prevResponses];
          updatedResponses[index] = {
            ...newQuestion,
            transactionId: data.transactionId,
            question: "@RaterAgent " + data.question,
            answer: data.response,
          };
          return updatedResponses;
        });

        scrollToBottomForAnswer(true);
      })
      .catch((error: any) => {
        setQuestionLoading(false);
        setQuestion("");
        console.error("Data not found in the response");
      });
  };
  const updateReaction = (chatId: string, like: boolean, disLike: boolean) => {
    const token = CommonValues.GetToken();

    const data = {
      ChatId: chatId,
      Like: like,
      DisLike: disLike,
    };

    const config = {
      method: "put",
      url: `${process.env.REACT_APP_NcSupport_Url}/api/ncsupportbot/nc-support-bot-reaction`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {})
      .catch((error: any) => {
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while updating reaction",
            life: 3000,
          });
        }
      });
  };
  const updateFeedback = (chatId: string, feedBack: string) => {
    const token = CommonValues.GetToken();

    const data = {
      ChatId: chatId,
      Feedback: feedBack,
    };

    const config = {
      method: "put",
      url: `${process.env.REACT_APP_NcSupport_Url}/api/ncsupportbot/nc-support-bot-feedback`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        if (feedBack !== "" && feedBack !== null && feedBack !== undefined) {
          toast.current.show({
            severity: "success",
            detail: "Feedback saved successfully.",
            life: 3000,
          });
          setChatResponses((prevResponses) =>
            prevResponses.map((response) =>
              response.chatId === chatId
                ? {
                    ...response,
                    feedback: "",
                    showFeedback: false,
                    feedbackLoading: false,
                  }
                : response
            )
          );
        }
      })
      .catch((error: any) => {
        setChatResponses((prevResponses) =>
          prevResponses.map((response) =>
            response.chatId === chatId
              ? { ...response, feedbackLoading: false }
              : response
          )
        );
        if (error.response.status == 401) {
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while updating feedback",
            life: 3000,
          });
        }
      });
  };
  const onCopyResponseClick = (copiedData: any) => {
    navigator.clipboard
      .writeText(copiedData)
      .then(() => {
        toast.current.show({
          severity: "success",
          detail: "Copied  Successfully!",
          life: 3000,
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          detail: "Unknown error occurred while coping data",
          life: 3000,
        });
      });
  };
  const onFollowupQuestionClick = (followupQuestion: any) => {
    sendQuestion(followupQuestion.substring(3));
  };

  const onSourceClick = (source: string) => {
    window.open(source, "_blank");
  };

  const showImageSidebar = (index: number, isHidden: boolean) => {
    const updatedChatResponses = [...chatResponses];
    updatedChatResponses[index].imageSidebar = isHidden;
    setChatResponses(updatedChatResponses);
  };
  return (
    <div className="landing-page">
      <Toast ref={toast} />
      <div style={{ minHeight: `${chatBotEmbeded ? "6.4vh" : "7.7vh"}` }}>
        <Header
          setShowConversationHistory={setShowConversationHistory}
          chatBotEmbeded={chatBotEmbeded}
        />
      </div>
      <div style={{ minHeight: `${chatBotEmbeded ? "93.6vh" : "79.7vh"}` }}>
        <div
          className={`row mx-auto ${
            chatBotEmbeded
              ? "nc-chat-bot-main-div-embeded"
              : "nc-chat-bot-main-div"
          }`}
        >
          {!chatBotEmbeded && (
            <div
              className={`${
                showConversationHistory
                  ? "col-lg-3 col-md-3 col-sm-3 col-3"
                  : "col-lg-1 col-md-1 col-sm-1 col-1"
              } d-flex px-0`}
            >
              <div
                className={
                  showConversationHistory
                    ? "chat-bot-sidebar-main-div-on-show-history"
                    : "chat-bot-sidebar-main-div"
                }
              >
                <HomeSidebar
                  setShowConversationHistory={setShowConversationHistory}
                  chatBotEmbeded={chatBotEmbeded}
                />
              </div>
              {showConversationHistory && (
                <div className="col conversation-history">
                  <NCChatBotConversationHistory
                    setShowConversationHistory={setShowConversationHistory}
                    chatBotEmbeded={chatBotEmbeded}
                  />
                </div>
              )}
            </div>
          )}
          <div
            className={`${
              chatBotEmbeded
                ? "col-lg-11 col-md-11 col-sm-11 col-11 chat-bot-response-area-embeded"
                : `${
                    showConversationHistory
                      ? "col-lg-9 col-md-9 col-sm-9 col-9"
                      : "col-lg-11 col-md-11 col-sm-11 col-11"
                  } chat-bot-response-area`
            } d-flex px-0`}
            ref={chatContainerRef}
          >
            {showConversationHistory && chatBotEmbeded && (
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 nc-chat-bot-main-div-embeded">
                <NCChatBotConversationHistory
                  setShowConversationHistory={setShowConversationHistory}
                  chatBotEmbeded={chatBotEmbeded}
                />
              </div>
            )}
            {!showConversationHistory && chatBotEmbeded && (
              <>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-4">
                  {!conversationLoading &&
                    chatResponses.length > 0 &&
                    chatResponses.map((response, index) => {
                      const trimmedAnswer = response.answer.trim();
                      let mainAnswer = trimmedAnswer;
                      let followUpQuestions: string[] = [];

                      if (trimmedAnswer.includes("$$FollowupQuestions$$")) {
                        const answerParts = trimmedAnswer.split(
                          "$$FollowupQuestions$$"
                        );
                        mainAnswer = answerParts[0];
                        followUpQuestions = answerParts[1]
                          ? answerParts[1]
                              .trim()
                              .split("\n")
                              .filter((q) => q)
                          : [];
                      } else if (
                        response.answer.includes("$$PlotlyDataStart$$")
                      ) {
                        let splitedAnswer = response.answer.split(
                          "$$PlotlyDataStart$$"
                        );
                        mainAnswer = splitedAnswer[0];
                      } else {
                        const answerParts =
                          trimmedAnswer.split("FollowupQuestions");
                        mainAnswer = answerParts[0];
                        followUpQuestions = answerParts[1]
                          ? answerParts[1]
                              .trim()
                              .split("\n")
                              .filter((q) => q)
                          : [];
                      }

                      const isLastAnswer = index === chatResponses.length - 1;

                      return (
                        <div
                          id="last-answer"
                          key={response.chatId}
                          className={`col-12 ${
                            isLastAnswer && !questionLoading
                              ? "last-answer-block-nc-chat-bot"
                              : "question-answer-block"
                          }`}
                        >
                          <div className="d-flex flex-direction-row align-items-center">
                            {!chatBotEmbeded && (
                              <span className="me-1 avtar border-rounded">
                                {avatarName}
                              </span>
                            )}
                            <div className="mb-1 text-14 nc-chat-bot-question">
                              <b>{response.question}</b>
                            </div>
                          </div>
                          <div className="d-flex flex-direction-row">
                            {!chatBotEmbeded && (
                              <span className="me-1">
                                <img
                                  alt="NowCerts Logo"
                                  src={AnswerIcon}
                                  className="me-2"
                                  height={28}
                                />
                              </span>
                            )}
                            {isLastAnswer && questionLoading ? (
                              <div
                                id="last-question"
                                className={`row w-100 ${
                                  isLastAnswer && questionLoading
                                    ? "last-question-block-nc-chat-bot"
                                    : "question-answer-block"
                                }`}
                              >
                                <div className="col-12">
                                  <Skeleton className="mb-2"></Skeleton>
                                </div>
                                <div className="col-12">
                                  <Skeleton
                                    width="70%"
                                    className="mb-2 d-block"
                                  ></Skeleton>
                                </div>

                                <div className="col-12">
                                  <Skeleton
                                    width="40%"
                                    className="mb-2 d-block"
                                  ></Skeleton>
                                </div>
                                <div className="col-12">
                                  <Skeleton
                                    width="20%"
                                    className="mb-2 d-block"
                                  ></Skeleton>
                                </div>
                              </div>
                            ) : (
                              <div className="mx-2">
                                <ReactMarkdown
                                  remarkPlugins={[remarkGfm]}
                                  className="answer-block"
                                >
                                  {mainAnswer}
                                </ReactMarkdown>
                                {response.plotlyData.data !== "" && (
                                  <Plot
                                    data={response.plotlyData.data}
                                    layout={response.plotlyData.layout}
                                  />
                                )}
                                {response.imageSidebar && (
                                  <Sidebar
                                    visible={response.imageSidebar}
                                    position="right"
                                    onHide={() =>
                                      showImageSidebar(index, false)
                                    }
                                    style={{ width: "30%" }}
                                    showCloseIcon={false}
                                  >
                                    {response.imageURLs.length > 0 && (
                                      <>
                                        {response.imageURLs.map((image, i) => (
                                          <div
                                            className="row"
                                            key={i + 1}
                                          >
                                            <span className="text-center">
                                              Image {i + 1}
                                            </span>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center mb-3">
                                              <img
                                                alt="NowCerts Support Center"
                                                src={image}
                                                height="100%"
                                                width="100%"
                                              />
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                    )}
                                  </Sidebar>
                                )}
                                {response.sources.length > 0 &&
                                  !response.responseStreaming && (
                                    <>
                                      <h6>Sources:</h6>
                                      {response.imageURLs.length > 0 && (
                                        <div
                                          className="sources-box me-3 "
                                          onClick={() =>
                                            showImageSidebar(index, true)
                                          }
                                        >
                                          <span className="source-wrap d-flex">
                                            <span className="namebox">
                                              Images
                                            </span>
                                          </span>
                                        </div>
                                      )}
                                      {response.videoURLs.length > 0 &&
                                        response.videoURLs.map((source, i) => (
                                          <div
                                            key={i}
                                            className="sources-box me-3 "
                                            onClick={() =>
                                              onSourceClick(source)
                                            }
                                          >
                                            <span className="source-wrap d-flex">
                                              <span className="namebox">
                                                Video {i + 1}
                                              </span>
                                            </span>
                                          </div>
                                        ))}
                                      {response.sources.map((source, i) => (
                                        <div
                                          key={i}
                                          className="sources-box me-3 "
                                          onClick={() => onSourceClick(source)}
                                        >
                                          <span className="source-wrap d-flex">
                                            <span className="namebox">
                                              Reference {i + 1}
                                            </span>
                                          </span>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                <div className="icon-box-embeded mb-2">
                                  <i
                                    className={
                                      response.like
                                        ? "fa fa-thumbs-up active"
                                        : "fa fa-thumbs-up"
                                    }
                                    aria-hidden="true"
                                    title="Like"
                                    onClick={() =>
                                      onThumbsUpClick(
                                        response.chatId,
                                        !response.like,
                                        false
                                      )
                                    }
                                  ></i>
                                  <i
                                    className={
                                      response.disLike
                                        ? "dislike fa fa-thumbs-down active"
                                        : "fa fa-thumbs-down"
                                    }
                                    aria-hidden="true"
                                    title="DisLike"
                                    onClick={() =>
                                      onThumbsDownClick(
                                        response.chatId,
                                        false,
                                        !response.disLike
                                      )
                                    }
                                  ></i>
                                  <i
                                    className="fa fa-comments-o"
                                    aria-hidden="true"
                                    title="Feedback"
                                    onClick={() =>
                                      toggleFeedbackVisibility(response.chatId)
                                    }
                                  ></i>

                                  {response.showFeedback && (
                                    <div className="feedback-box mb-3">
                                      <div className="row">
                                        <div className="col-12">
                                          <span
                                            className="close-feedback"
                                            onClick={() =>
                                              toggleFeedbackVisibility(
                                                response.chatId
                                              )
                                            }
                                          >
                                            {" "}
                                            <i
                                              className="fa fa-close "
                                              aria-hidden="true"
                                              title="Close"
                                            ></i>
                                          </span>
                                          <h6>
                                            Your feedback will improve this
                                            product.
                                          </h6>
                                          <div className="">
                                            <textarea
                                              name="feedback"
                                              onChange={(e) =>
                                                onFeedbackChange(
                                                  response.chatId,
                                                  e.target.value
                                                )
                                              }
                                              value={response.feedback}
                                              className="form-control mb-3 "
                                              rows={4}
                                              placeholder="Your feedback..."
                                            />
                                          </div>
                                          {response.feedbackLoading ? (
                                            <div className="col-12 d-flex justify-content-end">
                                              <SupportLoader />
                                            </div>
                                          ) : (
                                            <div className="col-12 text-end">
                                              <input
                                                className="btn btn-outline-info "
                                                type="button"
                                                value="Submit"
                                                onClick={() =>
                                                  onFeedbackSubmit(
                                                    response.chatId,
                                                    response.feedback
                                                  )
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>{" "}
                                    </div>
                                  )}
                                </div>
                                {followUpQuestions.length > 0 && (
                                  <>
                                    <h6>Follow-up Questions:</h6>

                                    {followUpQuestions.map((question, i) => (
                                      <span
                                        key={i}
                                        className="followup-que"
                                        onClick={() =>
                                          onFollowupQuestionClick(question)
                                        }
                                      >
                                        {question}
                                      </span>
                                    ))}
                                  </>
                                )}{" "}
                                {response.responseStreaming && (
                                  <div className="d-flex justify-content-center">
                                    <SupportLoader />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {conversationLoading ? (
                    <div className="col chat-bot-response-area-loader-placeholder d-flex justify-content-center align-items-center">
                      <div className="d-flex justify-content-center">
                        <SupportLoader />
                      </div>
                    </div>
                  ) : (
                    chatResponses.length === 0 && (
                      <div className="col chat-bot-response-area-loader-placeholder d-flex justify-content-center align-items-center">
                        {chatBotEmbeded ? (
                          <span className="text-16">
                            How may I assist you today?
                          </span>
                        ) : (
                          <h4 className="">How may I assist you today?</h4>
                        )}
                      </div>
                    )
                  )}
                </div>

                <div className="question-text-embeded">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 px-4 py-1">
                      <Mention
                        value={question}
                        onChange={onQuestionChange}
                        suggestions={suggestions}
                        onSearch={onSearch}
                        field="nickname"
                        placeholder="Ask me anything about NowCerts..."
                        rows={2}
                        itemTemplate={itemTemplate}
                        name="question"
                        className="nc-chat-bot-mention-textarea-embeded px-5"
                        maxLength={2000}
                        style={{
                          minHeight: `${Math.max(2, question.length / 150)}em`,
                        }}
                        onKeyDown={onEnterClick}
                      />

                      <span className="info-msg">
                        Support Bot can make mistakes. Check important info.
                      </span>
                      <img
                        src={CommunicationIcon}
                        onClick={() => sendQuestion(question)}
                        className={`${
                          showConversationHistory
                            ? "communication-icon-on-show-conversation-history"
                            : "communication-icon"
                        }`}
                        style={{
                          right: "12%",
                          bottom: "28%",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {!chatBotEmbeded && (
              <>
                <div
                  className={`col-lg-2 col-md-2 col-sm-2 col-2
                  `}
                ></div>
                <div
                  className={`col-lg-8 col-md-8 col-sm-8 col-8
                   p-4`}
                >
                  {!conversationLoading &&
                    chatResponses.length > 0 &&
                    chatResponses.map((response, index) => {
                      const trimmedAnswer = response.answer.trim();
                      let mainAnswer = trimmedAnswer;
                      let followUpQuestions: string[] = [];

                      if (trimmedAnswer.includes("$$FollowupQuestions$$")) {
                        const answerParts = trimmedAnswer.split(
                          "$$FollowupQuestions$$"
                        );
                        mainAnswer = answerParts[0];
                        followUpQuestions = answerParts[1]
                          ? answerParts[1]
                              .trim()
                              .split("\n")
                              .filter((q) => q)
                          : [];
                      } else if (
                        response.answer.includes("$$PlotlyDataStart$$")
                      ) {
                        let splitedAnswer = response.answer.split(
                          "$$PlotlyDataStart$$"
                        );
                        mainAnswer = splitedAnswer[0];
                      } else {
                        const answerParts =
                          trimmedAnswer.split("FollowupQuestions");
                        mainAnswer = answerParts[0];
                        followUpQuestions = answerParts[1]
                          ? answerParts[1]
                              .trim()
                              .split("\n")
                              .filter((q) => q)
                          : [];
                      }

                      const isLastAnswer = index === chatResponses.length - 1;

                      return (
                        <div
                          id="last-answer"
                          key={response.chatId}
                          className={`col-12 ${
                            isLastAnswer && !questionLoading
                              ? "last-answer-block-nc-chat-bot"
                              : "question-answer-block"
                          }`}
                        >
                          <div className="d-flex flex-direction-row align-items-center">
                            {!chatBotEmbeded && (
                              <span className="me-1 avtar border-rounded">
                                {avatarName}
                              </span>
                            )}
                            <div className="mb-1 text-14 nc-chat-bot-question">
                              <b>{response.question}</b>
                            </div>
                          </div>
                          <div className="d-flex flex-direction-row">
                            {!chatBotEmbeded && (
                              <span className="me-1">
                                <img
                                  alt="NowCerts Logo"
                                  src={AnswerIcon}
                                  className="me-2"
                                  height={28}
                                />
                              </span>
                            )}
                            {isLastAnswer && questionLoading ? (
                              <div
                                id="last-question"
                                className={`row w-100 ${
                                  isLastAnswer && questionLoading
                                    ? "last-question-block-nc-chat-bot"
                                    : "question-answer-block"
                                }`}
                              >
                                <div className="col-12">
                                  <Skeleton className="mb-2"></Skeleton>
                                </div>
                                <div className="col-12">
                                  <Skeleton
                                    width="70%"
                                    className="mb-2 d-block"
                                  ></Skeleton>
                                </div>

                                <div className="col-12">
                                  <Skeleton
                                    width="40%"
                                    className="mb-2 d-block"
                                  ></Skeleton>
                                </div>
                                <div className="col-12">
                                  <Skeleton
                                    width="20%"
                                    className="mb-2 d-block"
                                  ></Skeleton>
                                </div>
                              </div>
                            ) : (
                              <div className="mx-2">
                                <ReactMarkdown
                                  remarkPlugins={[remarkGfm]}
                                  className="answer-block"
                                >
                                  {mainAnswer}
                                </ReactMarkdown>
                                {response.plotlyData.data !== "" && (
                                  <Plot
                                    data={response.plotlyData.data}
                                    layout={response.plotlyData.layout}
                                  />
                                )}
                                {response.imageSidebar && (
                                  <Sidebar
                                    visible={response.imageSidebar}
                                    position="right"
                                    onHide={() =>
                                      showImageSidebar(index, false)
                                    }
                                    style={{ width: "30%" }}
                                  >
                                    {response.imageURLs.length > 0 && (
                                      <>
                                        {response.imageURLs.map((image, i) => (
                                          <div
                                            className="row"
                                            key={i + 1}
                                          >
                                            <span className="text-center">
                                              Image {i + 1}
                                            </span>
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center mb-3">
                                              <img
                                                alt="NowCerts Support Center"
                                                src={image}
                                                height="100%"
                                                width="100%"
                                              />
                                            </div>
                                          </div>
                                        ))}
                                      </>
                                    )}
                                  </Sidebar>
                                )}
                                {response.sources.length > 0 &&
                                  !response.responseStreaming && (
                                    <>
                                      <h6>Sources:</h6>
                                      {response.imageURLs.length > 0 && (
                                        <div
                                          className="sources-box me-3 "
                                          onClick={() =>
                                            showImageSidebar(index, true)
                                          }
                                        >
                                          <span className="source-wrap d-flex">
                                            <span className="namebox">
                                              Images
                                            </span>
                                          </span>
                                        </div>
                                      )}
                                      {response.videoURLs.length > 0 &&
                                        response.videoURLs.map((source, i) => (
                                          <div
                                            key={i}
                                            className="sources-box me-3 "
                                            onClick={() =>
                                              onSourceClick(source)
                                            }
                                          >
                                            <span className="source-wrap d-flex">
                                              <span className="namebox">
                                                Video {i + 1}
                                              </span>
                                            </span>
                                          </div>
                                        ))}
                                      {response.sources.map((source, i) => (
                                        <div
                                          key={i}
                                          className="sources-box me-3 "
                                          onClick={() => onSourceClick(source)}
                                        >
                                          <span className="source-wrap d-flex">
                                            <span className="namebox">
                                              Reference {i + 1}
                                            </span>
                                          </span>
                                        </div>
                                      ))}
                                    </>
                                  )}
                                <div className="icon-box mb-2">
                                  <i
                                    className={
                                      response.like
                                        ? "fa fa-thumbs-up active"
                                        : "fa fa-thumbs-up"
                                    }
                                    aria-hidden="true"
                                    title="Like"
                                    onClick={() =>
                                      onThumbsUpClick(
                                        response.chatId,
                                        !response.like,
                                        false
                                      )
                                    }
                                  ></i>
                                  <i
                                    className={
                                      response.disLike
                                        ? "dislike fa fa-thumbs-down active"
                                        : "fa fa-thumbs-down"
                                    }
                                    aria-hidden="true"
                                    title="DisLike"
                                    onClick={() =>
                                      onThumbsDownClick(
                                        response.chatId,
                                        false,
                                        !response.disLike
                                      )
                                    }
                                  ></i>
                                  <i
                                    className="fa fa-solid fa-copy"
                                    aria-hidden="true"
                                    title="Copy response"
                                    onClick={() =>
                                      onCopyResponseClick(mainAnswer)
                                    }
                                  ></i>
                                  <i
                                    className="fa fa-comments-o"
                                    aria-hidden="true"
                                    title="Feedback"
                                    onClick={() =>
                                      toggleFeedbackVisibility(response.chatId)
                                    }
                                  ></i>

                                  {response.showFeedback && (
                                    <div className="feedback-box mb-3">
                                      <div className="row">
                                        <div className="col-12">
                                          <span
                                            className="close-feedback"
                                            onClick={() =>
                                              toggleFeedbackVisibility(
                                                response.chatId
                                              )
                                            }
                                          >
                                            {" "}
                                            <i
                                              className="fa fa-close "
                                              aria-hidden="true"
                                              title="Close"
                                            ></i>
                                          </span>
                                          <h6>
                                            Your feedback will improve this
                                            product.
                                          </h6>
                                          <div className="">
                                            <textarea
                                              name="feedback"
                                              onChange={(e) =>
                                                onFeedbackChange(
                                                  response.chatId,
                                                  e.target.value
                                                )
                                              }
                                              value={response.feedback}
                                              className="form-control mb-3 "
                                              rows={4}
                                              placeholder="Your feedback..."
                                            />
                                          </div>
                                          {response.feedbackLoading ? (
                                            <div className="col-12 d-flex justify-content-end">
                                              <SupportLoader />
                                            </div>
                                          ) : (
                                            <div className="col-12 text-end">
                                              <input
                                                className="btn btn-outline-info "
                                                type="button"
                                                value="Submit"
                                                onClick={() =>
                                                  onFeedbackSubmit(
                                                    response.chatId,
                                                    response.feedback
                                                  )
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>{" "}
                                    </div>
                                  )}
                                </div>
                                {followUpQuestions.length > 0 && (
                                  <>
                                    <h6>Follow-up Questions:</h6>

                                    {followUpQuestions.map((question, i) => (
                                      <span
                                        key={i}
                                        className="followup-que"
                                        onClick={() =>
                                          onFollowupQuestionClick(question)
                                        }
                                      >
                                        {question}
                                      </span>
                                    ))}
                                  </>
                                )}{" "}
                                {response.responseStreaming && (
                                  <div className="d-flex justify-content-center">
                                    <SupportLoader />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  {conversationLoading ? (
                    <div className="col chat-bot-response-area-loader-placeholder d-flex justify-content-center align-items-center">
                      <div className="d-flex justify-content-center">
                        <SupportLoader />
                      </div>
                    </div>
                  ) : (
                    chatResponses.length === 0 && (
                      <div className="col chat-bot-response-area-loader-placeholder d-flex justify-content-center align-items-center">
                        {chatBotEmbeded ? (
                          <span className="text-16">
                            How may I assist you today?
                          </span>
                        ) : (
                          <h4 className="">How may I assist you today?</h4>
                        )}
                      </div>
                    )
                  )}
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-2"></div>
              </>
            )}
          </div>
          {chatBotEmbeded && (
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 d-flex px-0">
              <div
                className={`chat-bot-sidebar-main-div-embeded
                }`}
              >
                <HomeSidebar
                  setShowConversationHistory={setShowConversationHistory}
                  chatBotEmbeded={chatBotEmbeded}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {!chatBotEmbeded && (
        <div style={{ minHeight: "12.6vh" }}>
          <div className="question-text">
            <div className="position-relative question-text-div">
              <div className="row">
                {
                  <div
                    className={`${
                      showConversationHistory
                        ? "col-lg-4 col-md-4 col-sm-4 col-4"
                        : "col-lg-3 col-md-3 col-sm-3 col-3"
                    }`}
                  ></div>
                }
                <div
                  className={`${
                    chatBotEmbeded
                      ? "col-lg-10 col-md-10 col-sm-10 col-10"
                      : "col-lg-7 col-md-7 col-sm-7 col-7"
                  }`}
                >
                  <div className="row">
                    {showConversationHistory && (
                      <div className="col-lg-1 col-md-1 col-sm-1 col-1"></div>
                    )}
                    <div
                      className={`${
                        showConversationHistory
                          ? "col-lg-10 col-md-10 col-sm-10 col-10"
                          : "col-lg-12 col-md-12 col-sm-12 col-12"
                      }`}
                    >
                      {" "}
                      {/* <textarea
                    name="question"
                    onChange={onQuestionChange}
                    value={question}
                    className="form-control question-textarea mb-2 w-100"
                    rows={2}
                    placeholder="Ask me anything about NowCerts..."
                    maxLength={2000}
                    style={{
                      minHeight: `${Math.max(2, question.length / 150)}em`,
                    }}
                    onKeyDown={onEnterClick}
                  /> */}
                      <Mention
                        value={question}
                        onChange={onQuestionChange}
                        suggestions={suggestions}
                        onSearch={onSearch}
                        field="nickname"
                        placeholder="Ask me anything about NowCerts..."
                        rows={2}
                        itemTemplate={itemTemplate}
                        name="question"
                        className="nc-chat-bot-mention-textarea"
                        maxLength={2000}
                        style={{
                          minHeight: `${Math.max(2, question.length / 150)}em`,
                        }}
                        onKeyDown={onEnterClick}
                      />
                      <span className="info-msg mb-2">
                        Support Bot can make mistakes. Check important info.
                      </span>
                      <img
                        src={CommunicationIcon}
                        onClick={() => sendQuestion(question)}
                        className={`${
                          showConversationHistory
                            ? "communication-icon-on-show-conversation-history"
                            : "communication-icon-nc-chat-bot"
                        }`}
                        style={{ right: `${chatBotEmbeded ? "7%" : ""}` }}
                      />
                    </div>
                  </div>
                  {showConversationHistory && (
                    <div className="col-lg-1 col-md-1 col-sm-1 col-1"></div>
                  )}
                </div>

                <div
                  className={`col-lg-${
                    chatBotEmbeded ? "1" : showConversationHistory ? "1" : "3"
                  } col-md-${
                    chatBotEmbeded ? "1" : showConversationHistory ? "1" : "3"
                  } col-sm-${
                    chatBotEmbeded ? "1" : showConversationHistory ? "1" : "3"
                  } col-${
                    chatBotEmbeded ? "1" : showConversationHistory ? "1" : "3"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
