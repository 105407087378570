import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import MomentumLogo from "../Assets/Images/Nowcerts.png";
import AccessDenied from "../Assets/Images/not-allowed-symbol.svg";
import Header from "../Common/Header";
import CommonValues from "../Common/Utils";
import Loader from "../CommonControls/SupportLoader";
import { Guid } from "js-guid";
import { Toast } from "primereact/toast";

export default function ValidateNowcerts() {
  const [loading, setLoading] = useState(false);
  const toast: any = useRef(null);
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const embeded = searchParams.get("embeded") === "true";

  useEffect(() => {
    CommonValues.ClearAllValues();
    console.log(JSON.stringify(window.location));
    validateNowcertsToken(token);
  }, []);

  const getNcToken = (ncSetupUserName: any) => {
    let token = CommonValues.GetToken();
    const data = JSON.stringify({
      NCUserName: ncSetupUserName,
    });
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/verifyMomentumCreds`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        if (response.data != "" && response.data != null) {
          localStorage.setItem("NCToken", response.data.access_token);
          toast.current.show({
            severity: "success",
            detail: "Got NowCerts Token.",
            life: 3000,
          });
        }
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          }
          // else if (error.response.status == 409) {
          //   toast.current.show({
          //     severity: "info",
          //     detail:
          //       "It look likes you are not registered with NowCerts, Please register first.",
          //     life: 3000,
          //   });
          // } else {
          //   toast.current.show({
          //     severity: "error",
          //     detail: "Unknown error while updating the NowCerts Settings",
          //     life: 3000,
          //   });
          // }
        }
        //  else {
        //   toast.current.show({
        //     severity: "error",
        //     detail: "Unknown error while verify momentum credentials",
        //     life: 3000,
        //   });
        // }
      });
  };
  const validateNowcertsToken = (token: any) => {
    setLoading(true);
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_AUTHAPI}/api/account/validateNowcertsToken?token=${token}`,
    };

    axios(config)
      .then((response: any) => {
        if (response.data.token == "") {
          CommonValues.Logout(navigate);
        } else {
          localStorage.setItem("SocialLogin", "true");
          localStorage.setItem("igtoken", response.data.token);
          localStorage.setItem("tenantId", response.data.tenantId);
          localStorage.setItem("UserName", response.data.userName);
          localStorage.setItem("RoleId", response.data.roleId);
          localStorage.setItem("AvatarName", response.data.avatarName);
          localStorage.setItem(
            "BusinessName",
            response.data.firstName + " " + response.data.lastName
          );
          let conversationId = Guid.newGuid();
          getNcToken(response.data.userName);
          if (embeded) {
            navigate(`/bot/${conversationId}/embeded`, {
              state: { embeded },
            });
          } else {
            navigate(`/bot/${conversationId}`, { state: { embeded } });
          }
        }
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } 
          // else {
          //   toast.current.show({
          //     severity: "error",
          //     detail: "Unknown error while getting details!",
          //     life: 3000,
          //   });
          // }
        }
      });
  };
  return (
    <div className="support-login-page">
      <Toast ref={toast} />
      <Header chatBotEmbeded={embeded} />
      <div className="content-wrapper container-fluid  d-flex justify-content-center">
        <div className=" main-content d-flex align-items-center ">
          <div className="row login-box mx-auto ">
            <div className="col-12 text-center ">
              <div
                className={`${
                  embeded
                    ? "authenticating-block-embeded"
                    : "authenticating-block"
                }  text-center`}
                style={{
                  minHeight: `${embeded ? "" : "200px"}`,
                }}
              >
                {loading ? (
                  <>
                    <img
                      alt="Momentum Logo"
                      src={MomentumLogo}
                      height="40px"
                      className="mt-3"
                    />
                    <div className="mt-3">
                      <h4 className="">Launching Momentum AI Center (Beta)</h4>
                    </div>
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={AccessDenied}
                      height={35}
                      width={35}
                    />
                    <p className="not-authorize-text">
                      <b>You are not authorized</b>
                    </p>
                    <p className="d-block">
                      It seems like you don't have permission to use this
                      portal.
                      <br /> Please sign in with a different account.{" "}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
